.slider_image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  left: 0;
}
.slider_content-wrapper {
  z-index: 2;
  padding-top: 25vh;
  position: absolute;
  left: 0;
  width: 100%;
}
.slider_content-container {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.slider_content {
  max-width: 50%;
  padding: 40px;
  backdrop-filter: blur(5px);
  width: fit-content;
  background-color: #ffffff4d;
}

.slider_content > h2 {
  font-size: 35px;
  color: #141527;
  line-height: 1.2;
  margin-bottom: 20px;
}

.slider_content > p {
  font-size: 19px;
  color: #282828;
  line-height: 1.2;
}

.service_img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

@media (max-width: 1000px) {
  .slider_content {
    max-width: 80%;
  }

  .slider_content > h2 {
    font-size: 28px;
  }
  .slider_content > p {
    font-size: 17px;
  }
}

@media (max-width: 600px) {
  .slider_content {
    padding: 15px;
    max-width: 100%;
    /* background-color: transparent; */
  }

  .slider_content > h2 {
    font-size: 25px;
  }
  .slider_content > p {
    font-size: 15px;
  }
}

@keyframes anime {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0px);
  }
}

.fixed-link {
  color: white;

  background: rgb(79 70 229);

  position: fixed;
  bottom: 25px;
  right: 50px;
  z-index: 50;

  padding: 5px 10px;
  display: block;
  border-radius: 25px;
  display: flex;
  align-items: center;
  animation: anime 1.2s infinite;
}

.fixed-link i {
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_container {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_nav {
  display: flex;
  align-items: center;
  gap: 20px;
}
.header_nav-item {
}
.header_nav-item-link {
    text-transform: uppercase;
}

.header_toggler {
  display: none;
}
.toggler-line {
  width: 100%;
  display: block;
  height: 2px;
  background-color: #000;
}


.open-menu {
    display: none;
}
.close-menu {
    display: none;
}


.contact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.contact_left {
    max-width: 400px;
    width: 100%;


}
.contact_right {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

}
.contact_input {
    padding: 8px 15px;
    outline: none;
    background-color: rgb(237, 236, 236);
    resize: none;
    border-radius: 6px;

}
.contact_submit-btn {
    text-transform: uppercase;


}


.faq-container {
    max-width: 700px;
    width: 100%;
    padding: 20px 30px;
    margin-right: auto;
    margin-left: auto;
}


.brands_wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}
.brand {
  width: 100%;
  padding: 10px;


}
.brand_image {
  width: 100%;
  height: 100px;
  object-fit: contain;
  transition: all 500ms ease;

}

.brand:hover .brand_image {
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .brands_wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
  .header_nav {
    display: none;
  }
  .header_toggler {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 6px;
  }

  .open-menu {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #0000006e;
    top: 0;
    left: 0;
    z-index: 50;
}
.close-menu {
    display: none;

}
.mobilenav {
    position: fixed;
    height: 100vh;
    max-width: 300px;
    width: 100%;
    left: 0;
    top: 0;
    padding: 20px 30px;
    background-color: #fff;
    z-index: 50;
    color: #000;
    overflow-y: auto;

}
.mobilenav_logo {
    display: block;
    width: fit-content;
    margin: 10px auto;
}
.mobilenav_logo-img {
    width: 90px;
    height: 90px;
    object-fit: contain;
}
.mobilenav .header_nav {
    flex-direction: column;
    display: flex;
}

.toggler-wrapper {
    display: flex;
    justify-content: flex-end;
}
.toggler-wrapper .header_toggler {
    gap: 0;
}
.toggler-wrapper .header_toggler .toggler-right {
    transform: rotate(45deg);
}
.toggler-wrapper .header_toggler .toggler-left {
    margin-top: -2px;
    transform: rotate(-45deg);
}
}




@media (max-width: 700px) {
  .contact {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .brands_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 400px) {
  .brands_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}